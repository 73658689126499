.right-bubble-container {
    align-self: flex-end;
    /* background-color: red; */
    display:inline-block;
    border-right: 18px solid transparent;
    border-left: 0px solid transparent;
    border-top: 12px solid white;
    position: relative;
    margin-top:4px;
    margin-bottom: 4px;
}

.right-message-box {
    padding:5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top:-12px;
    left:0px;

    background-color: white;
}