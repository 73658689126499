/* REACT-SELECT OVERRIDE */

.pg-tmr5-sselect-o {
    height: 100%;
}
.pg-tmr5-sselect__control {
    height: 100%;
    background-color: transparent!important;
    border: none!important;
    border-radius: 0!important;
}
.pg-tmr5-sselect__control--is-focused {
    border: transparent!important;
    box-shadow: none!important;
}
.pg-tmr5-sselect__single-value,
.pg-tmr5-sselect__placeholder {
    color: #213333!important;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-tmr5-sselect__menu {
    z-index: 800!important;
    border-radius: 5px;
    box-shadow: 14px 14px 20px 0 rgba(0,0,0,0.12);
}
.pg-tmr5-sselect__menu-list {
    padding: 15px 10px;
    min-height: 150px;
    color: #363636!important;
    font-size: 14px;
    font-weight: 400;
    font-family: Poppins, Arial;
}
.pg-tmr5-sselect__option {
    padding: 2px 12px;
}
.pg-tmr5-sselect__option--is-focused {
    cursor: pointer!important;
    color: #363636!important;
    background-color: #FFF!important;
}
.pg-tmr5-sselect__option--is-selected {
    color: #FFF!important;
    background-color: rgba(164, 164, 164, 0.5)!important;
}


.properties-section {
    padding-bottom: 250px;
    background-image: url("../../images/bgs/footer-2-bg.png");
    background-repeat: repeat-x;
    background-position: center bottom;
}

.back-link-wrapper {
    padding-top: 25px;
    padding-bottom: 15px;
}
.properties-back-icon {
    color: #BFBFBF;
}
.properties-back-link {
    background-color: transparent;
    border: none;
}
.properties-back-link:hover {
    text-decoration: none;
}
.properties-back-link:focus {
    outline: none;
}
.properties-back-text {
    margin-left: 10px;
    color: #1AAFE3;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}

/* CATEGORY & RESULT COUNT */

.pg-tmr5-catg-rcount-wrapper {
    padding-bottom: 30px;
}
.pg-tmr5-props-category {
    color: #363636;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 35px;
}
.pg-tmr5-props-result-count {
    color: #363636;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 25px;
}

/* FILTERING & SORTING */

.pg-tmr5-fs-wrapper-row {
    margin-bottom: 50px;
    height: 49px;
}
.pg-tmr5-fs-wrapper-col {
    background-color: #F8F8F8;
    border: none;
    border-radius: 5px;
}
.pg-tmr5-fs-wrapper-left {
    margin-left: 15px;
    background-image: url("../../images/icons/search.png");
    background-repeat: no-repeat;
    background-position: left center;
}
.pg-tmr5-filterdd-header {
    color: #213333;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 49px;
    background-color: transparent;
}

.pg-tmr5-noads-text-row {
    height: 150px;
    font-size: 15px;
    font-family: 'Poppins', Arial;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
}

.pg-tmr5-ad-status {
    margin-top: -10px;
    margin-left: auto;
    margin-right: auto;
    width: 50px;
    height: 19px;
    position: relative;
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 19px;
    text-align: center;
    border: none;
    border-radius: 10px;
}

.ad-details-wrapper {
    border-right: 1px solid #CECECE;
}
.ad-category {
    margin-bottom: 0.25rem;
    color: #A4A4A4;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.ad-title {
    margin-bottom: 0.25rem;
    color: #242424;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 25px;
}
.ad-location-user-row {
    margin-bottom: 0.75rem;
}
.ad-description {
    margin-bottom: 0.5rem;
    height: 46px;
    color: #8B8B8B;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ad-total-price-wrapper {
    margin-bottom: 0;
    color: #213333;
    font-family: 'Poppins', Arial;
}
.ad-total-price-label {
    font-size: 14px;
    font-weight: 400;
}
.ad-total-price-value {
    margin-left: 5px;
    font-size: 16px;
    font-weight: 600;
}
.ad-share-wrapper {
    padding: 30px 10px;
    padding-bottom: 30px;
    background-color: #F2F2F2;
    border: none;
    border-radius: 5px;
}
.ad-share-w-label {
    margin-bottom: 0;
    padding-bottom: 15px;
    color: #213333;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.ad-share-w-value {
    color: #363636;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}

/* KISSAN ADS - ICON, LABEL & VALUE */

.pg-tmr5-prop-kissan-ilv-wrapper {
    padding-left: 10px;
}
.pg-tmr5-prop-kissan-ilv-row {
    margin-bottom: 20px;
}
.pg-tmr5-prop-kissan-icon-wrapper {
    width: 39px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}
.pg-tmr5-prop-kissan-lv-wrapper {
    margin-left: 15px;
    display: inline-block;
    vertical-align: middle;
}
.pg-tmr5-prop-kissan-ly-label {
    margin-bottom: 0;
    color: #A4A4A4;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-tmr5-prop-kissan-ly-value {
    margin-bottom: 0;
    color: #213333;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 25px;
}

/* KISSAN ADS - AMOUNT & INTEREST BUTTON */

.pg-tmr5-ad-amount-label {
    margin-bottom: 0;
    color: #213333;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-tmr5-ad-amount-value {
    margin-bottom: 20px;
    color: #242424;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 35px;
}

.interest-link:hover {
    color: #000;
    text-decoration: none;
}
.view-more-button {
    height: 49px;
    color: #A4A4A4;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
    background-color: #F8F8F8;
    border: none;
    border-radius: 5px;
}
@media only screen and (max-width: 480px) {
    .ad-share-w-label {
        font-size: 12px !important;
    }

    .ad-share-wrapper {
        padding: 10px 10px !important;
    }
    .img-wrapper {
        padding-right: 0px;
    }
    .ad-details-wrapper{
        padding-left: 05px;
    }
    .horizontal-rule{
        height: 1px;
        width:90% !important;
        margin: auto;
        margin-top:15px;
        background-color: #A4A4A4;
    }

}
