.timeline--wrapper {
    width: 100%;
}
.timeline {
    width: 100%;
    position: relative;
}
.timeline:before {
    width: 0px;
    top: 0px;
    left: calc(17% + 12px);
    bottom: 0px;
    position: absolute;
    content: "";
    border: 2px solid #CCEEEE;
}
.timeline:after {
    display: table;
    content: "";
    clear: both;
}

@media only screen and (max-width: 768px) {
    .timeline:before {
        left: calc(1% + 6px);
    }
}

.body-container {
    position: relative;
    margin-left: 30px;
}
.timeline-item--no-children .body-container {
    background: transparent;
    box-shadow: none;
}
.body-container:after {
    content: "";
    display: table;
    clear: both;
}

.timeline-count-date {
    padding: 10px 15px;
    width: 90%;
    font-family: 'Poppins', Arial;
    text-align: right;
    background-color: #FFF;
    border-radius: 5px;
}
.timeline-count {
    color: #1AAFE3;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}
.timeline-date {
    color: #363636;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
}

.timeline-item-date {
    position: absolute;
    top: -12px;
    left: 0;
    background: #ddd;
    padding: 1px;
    -webkit-clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
    height: 52px;  
    box-sizing: border-box;
    width: 90%;
}
.timeline-item-dateinner {
    background: #e86971;
    color: #fff;
    padding: 0;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    border-right-color: transparent;
    -webkit-clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
    height: 50px;
    width: 100%;
    display: block;
    line-height: 52px;
    text-indent: 15px;
}

.entry {
    clear: both;
    position: relative;
}
.entry .title {
    margin-bottom: 0.5em;
    width: 16%;
    position: relative;
    float: left;
}
.entry .title:before {
    width: 20px;
    height: 20px;
    top: 25px;
    right: -35px;
    position: absolute;
    z-index: 99;
    content: "";
    background-color: #CCEEEE;
    border-radius: 100%;
    box-sizing: content-box;
}
.entry .body {
    margin: 0 0 3em;
    width: 80%;
    float: right;
    color: #8B8B8B;
    font-size: 14px;
    font-family: 'Poppins', Arial;
    font-weight: 400;
    line-height: 22px;
}
.entry .body p {
    text-align: justify;
    white-space: break-spaces;
}
.entry .body h1, .entry .body h2, .entry .body h3,
.entry .body h4, .entry .body h5, .entry .body h6 {
    margin: 0;
}
.entry .body p:first-child {
    margin-top: 0;
}
.entry .body .video-wrapper {
    cursor: pointer;
    position: relative;
}
.entry .body .img-wrapper {
    cursor: pointer;
    position: relative;
}
.entry .body .video-wrapper img,
.entry .body .img-wrapper img {
    border-radius: 5px;
}


@keyframes bounce-in {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
}

@media only screen and (max-width: 768px) {
    .entry .title {
        float: left;
        width: 70%;
    }
    .timeline-item-date {
        margin-left: 30px;
    }
    .entry .title:before {
        top: 15%;
        left: 3px;
        right: auto;
        z-index: 99;
    }
    .entry .body {
        margin: 20px 0 3em;
        float: right;
        width: 99%;
    }
}