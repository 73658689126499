.pg-1mw0-uprofile-section {
    padding-top: 55px;
    padding-bottom: 220px;
    background-color: #FFF;
    background-image: url("../../../images/bgs/footer-2-bg.png");
    background-repeat: repeat-x;
    background-position: center bottom;
}
.pg-1mw0-uname-col {
    margin-top: 10px;
}
.pg-1mw0-uname {
    color: #363636;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 30px;
}
.pg-1mw0-up-value-row {
    margin-top: 45px;
}
.pg-1mw0-up-value-col {
    padding: 20px 5px;
    border-bottom: 1px solid #D6E8E8;
}
.pg-1mw0-up-value-icon {
    width: 25px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}
.pg-1mw0-up-value-wrapper {
    margin-left: 15px;
    width: calc(100% - 40px);
    display: inline-block;
    vertical-align: middle;
}
.pg-1mw0-up-label {
    margin-bottom: 3px;
    color: #ABA7A7;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-1mw0-up-value {
    color: #363636;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-1mw0-up-value-alt {
    color: #363636;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-1mw0-up-pwd-value {
    font-size: 33px;
}
.pg-1mw0-up-add-btn {
    padding-right: 30px;
    width: 16px;
    height: 100%;
    background-image: url("../../../images/icons/pg-1mw0-add-icon.png");
    background-repeat: no-repeat;
    background-position: center center;
}
.pg-1mw0-up-edit-btn {
    padding-right: 30px;
    width: 16px;
    height: 100%;
    background-image: url("../../../images/icons/pg-1mw0-edit-icon.png");
    background-repeat: no-repeat;
    background-position: center center;
}
.pg-1mw0-up-add-btn:focus, .pg-1mw0-up-edit-btn:focus {
    box-shadow: none;
}
.pg-1mw0-up-changepwd-btn {
    padding-top: 5px;
    padding-bottom: 5px;
    height: 26px;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 14px;
    background-color: #1CA9E1;
    border: none;
    border-radius: 5px;
}