.compslider-rail-outer {
    width: 100%;
    height: 42px;
    position: absolute;
    cursor: pointer;
    border-radius: 5px;
    transform: translate(0%, -50%);
}
.compslider-rail-inner {
    width: 100%;
    height: 6px;
    position: absolute;
    pointer-events: none;
    background-color: rgb(255,255,255,0.16);
    border-radius: 5px;
    transform: translate(0%, -50%);
}

.compslider-handle-dummy {
    width: 28px;
    height: 42px;
    position: absolute;
    z-index: 5;
    cursor: pointer;
    background-color: none;
    transform: translate(-50%, -50%);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.compslider-handle {
    width: 22px;
    height: 22px;
    position: absolute;
    z-index: 2;
    border-radius: 50%;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.3);
    transform: translate(-50%, -50%);
}
.compslider-handle-kb {
    width: 22px;
    height: 22px;
    position: absolute;
    z-index: 2;
    border-radius: 50%;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.3);
    transform: translate(-50%, -50%);
}

.compslider-track {
    height: 6px;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    border-radius: 5px;
    transform: translate(0%, -50%);
}