/* USER ACTIONS SECTION */

.pg-xxxx-uactions-section {
    height: 53px;
    background-color: #1AAFE3;
}
.pg-xxxx-uactions-link {
    height: 50px;
    line-height: 50px;
    cursor: pointer;
}
.pg-xxxx-uactions-link a:hover {
	text-decoration: none;
}
.pg-xxxx-ul-icon {
    margin-right: 7px;
    width: 22px;
    height: 50px;
    float: left;
    background-repeat: no-repeat;
    background-position: center;
}
.pg-xxxx-ul-home-icon {
    background-image: url('../../../images/icons/pg-xxxx-home-icon.png');
}
.pg-xxxx-ul-chat-icon {
    background-image: url('../../../images/icons/pg-xxxx-chat-icon.png');
}
.pg-xxxx-ul-request-icon {
    background-image: url('../../../images/icons/pg-xxxx-request-icon.png');
}
.pg-xxxx-ul-accept-icon {
    background-image: url('../../../images/icons/pg-xxxx-accept-icon.png');
}
.pg-xxxx-ul-text {
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-xxxx-ul-count {
    width: 22px;
    height: 22px;
    left: -15px;
    bottom: 10px;
    position: absolute;
    color: #FFF;
    font-size: 11px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 22px;
    text-align: center;
    background-color: #E24D4D;
    border: none;
    border-radius: 50%;
}

/* REQUEST & ACCEPT DROPDOWNS */

.pg-xxxx-uprop-dd {
    padding: 0;
    width: 15rem;
    max-height: 20rem;
    top: 10px;
    right: 0;
    border: 0;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 5px;
    box-shadow: 14px 14px 20px 0 rgba(0, 0, 0, 0.25);
}
.pg-xxxx-uprop {
    padding: 0.25rem 0.75rem 0.40rem 0.75rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border-bottom: 1px solid #E9E7E7;
}
.pg-xxxx-uprop:active {
    background-color: transparent;
}
.pg-xxxx-uprop:last-child {
    border-bottom: none;
}
.pg-xxxx-uprop-img-wrapper {
    width: 40px;
    display: inline-block;
    vertical-align: middle;
}
.pg-xxxx-uprop-img {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.25);
}
.pg-xxxx-uprop-tu-wrapper {
    margin-left: 10px;
    width: calc(100% - 50px);
    display: inline-block;
    vertical-align: middle;
}
.pg-xxxx-uprop-title {
    margin-bottom: 5px;
    width: 100%;
    color: #242424;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 1.6;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.pg-xxxx-uprop-user {
    color: #363636;
    font-size: 11px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 1.44;
}
.pg-xxxx-uprop-icon-user {
    margin-right: 10px;
    width: 14px;
    height: 16px;
}