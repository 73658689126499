/* COMMON */

.pg-a67d-sect-heading-row {
    margin-bottom: 30px;
}
.pg-a67d-section-heading {
    color: #363636;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 25px;
}

/* BACK LINK SECTION */

.pg-a67d-blink-section {
    border-top: 1px solid #ECECEC;
}

/* AD DETAILS SECTION */

.pg-a67d-ad-det-section {
    padding-top: 25px;
    padding-bottom: 25px;
}
.pg-a67d-ad-det-col1 {
    border-right: 1px solid #CECECE;
}
.pg-a67d-ad-catgimg-status-wrapper {
    display: inline-block;
    vertical-align: middle;
}
.pg-a67d-ad-catgimg-wrapper {
    width: 124px;
    height: 124px;
    text-align: center;
    /*border: 2px solid #DBDBDB;*/
    border-radius: 50%;
}
.pg-a67d-ad-status {
    margin-top: -15px;
    margin-left: auto;
    margin-right: auto;
    width: 46px;
    height: 19px;
    position: relative;
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 19px;
    text-align: center;
    border: none;
    border-radius: 10px;
}
.pg-a67d-ad-det-wrapper {
    margin-left: 30px;
    display: inline-block;
    vertical-align: middle;
}
.pg-a67d-ad-category {
    margin-bottom: 8px;
    color: #A4A4A4;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-a67d-ad-title {
    margin-bottom: 8px;
    color: #242424;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 35px;
}
.pg-a67d-ad-location, .pg-a67d-ad-by {
    color: #363636;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-a67d-ad-tprice-wrapper {
    padding-left: 15px;
}
.pg-a67d-ad-tprice-label {
    margin-bottom: 10px;
    color: #213333;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-a67d-ad-tprice-value {
    margin-bottom: 0;
    color: #242424;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 35px;
}

/* GROUP DETAILS SECTION */

.pg-a67d-gd-int-section {
    padding-top: 30px;
}
.pg-a67d-gd-users-row {
    margin-bottom: 50px;
}
.pg-a67d-gd-user-row {
    margin-bottom: 20px;
}
.pg-a67d-gd-ushare-row {
    margin-bottom: 10px;  
}
.pg-a67d-gd-ushare {
    color: #363636;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-a67d-gd-us-pbar {
    height: 19px;
    background-color: #ECECEC;
}
.pg-a67d-gd-us-pbar-seek {
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 19px;
    text-align: center;
    background-color: #1AAFE3;
    border-right: 5px solid #FFF;
}
.pg-a67d-int-btn {
    min-width: 137px;
}
.pg-a67d-int-btn:hover {
    text-decoration: none;
}

/* SHARE-DETAILS SECTION */

.pg-a67d-sh-det-section {
    padding-top: 50px;
    padding-bottom: 190px;
    background-color: #F8F8F8;
    background-image: url("../../images/bgs/footer-1-bg.png");
    background-repeat: repeat-x;
    background-position: center bottom;
}
.pg-a67d-sh-det-row {
    margin-bottom: 20px;
}
.pg-a67d-mshare-wrapper,
.pg-a67d-oshare-wrapper {
    padding: 20px;
    border-radius: 5px;
}
.pg-a67d-mshare-heading,
.pg-a67d-oshare-heading {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}

.pg-a67d-mshare-wrapper {
    background-color: #1AAFE3;
}
.pg-a67d-mshare-heading {
    padding-bottom: 10px;
    color: #FFF;
    border-bottom: 1px solid #4FB9E4;
}
.pg-a67d-mshare-row {
    margin-top: 10px;
}
.pg-a67d-mshare-form-label {
    margin-bottom: 0;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-a67d-mshare-igroup .input-group-text {
    color: #324444;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 25px;
    background-color: #FFF;
    border-radius: 5px;
}
.pg-a67d-mshare-textbox {
    height: 38px;
    color: #363636;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
    background-color: #FFF;
    border-radius: 5px;
}
.pg-a67d-mshare-igroup .pg-a67d-mshare-textbox {
    padding-left: 0;
    border-left: 0;
}

.pg-a67d-oshare-wrapper {
    background-color: #FFFFFF;
}
.pg-a67d-oshare-heading {
    padding-bottom: 10px;
    color: #363636;
    border-bottom: 1px solid #D6E8E8;
}
.pg-a67d-oshare-row {
    margin-top: 25px;
}
.pg-a67d-icon-wrapper {
    display: inline-block;
    vertical-align: middle;
}
.pg-a67d-lv-wrapper {
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
}
.pg-a67d-oshare-label {
    margin-bottom: 0;
    color: #ABA7A7;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-a67d-oshare-value {
    margin-bottom: 0;
    color: #363636;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}

.pg-a67d-notes-row {
    margin-bottom: 20px;
}
.pg-a67d-sh-slider-row {
    margin-bottom: 20px;
}

/* SUCCESS MODAL */

.pg-a67d-tnclink {
    color: #1CA9E1;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 18px;
}

.mobile {
    display: none;
}


@media only screen and (max-width: 480px) {
    .no-mobile {
        display : none;
    }
    .mobile {
        display: flex;
    }
    .icon-box {
        display: flex;
        flex-direction: row;
        justify-content: start;
        padding-left: 0px !important;
    }
    .icon-text-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .pg-a67d-int-btn{
        width:100% !important
    }
}