/* COMMON FOR ALL SECTIONS */

.pg-nd75-sect-heading-row {
    margin-bottom: 30px;
}
.pg-nd75-section-heading {
    color: #363636;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 30px;
}
.pg-nd75-section-sleft {
    width: 20px;
    height: 100%;
    display: inline-block;
    cursor: pointer;
    background-image: url('../../../images/icons/pg-nd75-scroll-left.png');
    background-repeat: no-repeat;
    background-position: center;
}
.pg-nd75-section-sright {
    width: 20px;
    height: 100%;
    display: inline-block;
    cursor: pointer;
    background-image: url('../../../images/icons/pg-nd75-scroll-right.png');
    background-repeat: no-repeat;
    background-position: center;
}
.pg-nd75-section-maximize {
    width: 20px;
    height: 100%;
    cursor: pointer;
    display: inline-block;
    background-image: url('../../../images/icons/pg-nd75-maximize.png');
    background-repeat: no-repeat;
    background-position: center;
}
.pg-nd75-ad-col {
    margin-bottom: 30px;
    height: 108px;
}
.pg-nd75-ad-col a:hover {
    text-decoration: none;
}
.pg-nd75-ad-card {
    position: relative;
    border: none;
    border-radius: 5px;
    -webkit-box-shadow: 15px 15px 50px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 15px 15px 50px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 15px 15px 50px 0 rgba(0, 0, 0, 0.25);
}
.pg-nd75-wfa-overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 10;
    background-color: rgba(220, 219, 219, 0.89);
    border: none;
    border-radius: 5px;
}
.pg-nd75-wfa-text {
    top: 50%;
    left: 50%;
    position: absolute;
    color: #242424;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 21px;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
}
.pg-nd75-ad-card-col1 {
    width: 142px;
    position: relative;
    float: left
}
.pg-nd75-ad-img {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.pg-nd75-ad-price {
    margin: 0 auto;
    width: 80%;
    left: 0;
    right: 0;
    bottom: 10px;
    position: absolute;
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 18px;
    text-align: center;
    background-color: #37B837;
    border: none;
    border-radius: 10px;
}
.pg-nd75-ad-card-col2 {
    width: calc(100% - 142px);
    float: left
}
.pg-nd75-ad-status {
    width: 16px;
    height: 16px;
    top: 7px;
    right: 7px;
    position: absolute;
}
.pg-nd75-as-ok {
    background-image: url('../../../images/icons/pg-nd75-ad-ok.png');
    background-repeat: no-repeat;
    background-position: center;
}
.pg-nd75-as-reload {
    background-image: url('../../../images/icons/pg-nd75-ad-reload.png');
    background-repeat: no-repeat;
    background-position: center;
}
.pg-nd75-ad-card-body {
    padding: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
}
.pg-nd75-ad-title {
    margin-block-end: 5px;
    color: #242424;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 21px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.pg-nd75-ad-users, .pg-nd75-ad-days,
.pg-nd75-ad-profit, .pg-nd75-ad-qty,
.pg-nd75-ad-time, .pg-nd75-ad-crop {
    color: #363636;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 18px;
}

/* INDIVIDUAL SECTIONS */

.pg-nd75-sections-wrapper {
    padding-top: 55px;
    padding-bottom: 200px;
    background-color: #F8F8F8;
    background-image: url("../../../images/bgs/footer-1-bg.png");
    background-repeat: repeat-x;
    background-position: center bottom;
}
.pg-nd75-myprop-timebased-section {

}
.pg-nd75-myprop-profitbased-section {
    padding-top: 35px;
}
.pg-nd75-myprop-kissan-section {
    padding-top: 35px;
}