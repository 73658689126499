/* IMAGE UPLOADER */

.fileContainer {
    padding: 15px;
    min-height: 155px;
    background-color: #F2F2F2;
    border-radius: 5px;
    box-shadow: none;
}
.fileContainer .chooseFileButton {
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-image: url("../../images/icons/add-media.png");
    background-repeat: no-repeat;
    background-position: center center;
    border: none;
    outline: none;
    transition: all 0.2s ease-in;
}
.fileContainer .chooseFileButton:hover {
    background-color: transparent;
    background-image: url("../../images/icons/add-media.png");
    background-repeat: no-repeat;
    background-position: center center;
}
.fileContainer .uploadPictureContainer {
    margin: 10px;
    padding: 0;
    width: 14%;
    background-color: #edf2f6;
    border: none;
    border-radius: 5px;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.25);
}
.fileContainer .deleteImage {
    width: 21px;
    height: 21px;
    font-size: 10px;
    line-height: 21px;
    background-color: #DF3D3D;
}
.fileContainer .uploadPictureContainer img.uploadPicture {
    border: none;
    border-radius: 5px;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.25);
}