.pg-1mw0-uprofile-section {
    padding-top: 0px !important;
    padding-bottom: 220px;
    background-color: #FFF;
    background-image: url("../../../images/bgs/footer-2-bg.png");
    background-repeat: repeat-x;
    background-position: center bottom;
}
.pg-1mw0-uname-col {
    margin-top: 10px;
}
.pg-1mw0-uname {
    color: #363636;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 30px;
}
.pg-1mw0-up-value-row {
    margin-top: 45px;
}
.pg-1mw0-up-value-col {
    padding: 20px 5px;
    border-bottom: 1px solid #D6E8E8;
}
.pg-1mw0-up-value-icon {
    width: 25px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}
.pg-1mw0-up-value-wrapper {
    margin-left: 15px;
    width: calc(100% - 40px);
    display: inline-block;
    vertical-align: middle;
}
.pg-1mw0-up-label {
    margin-bottom: 3px;
    color: #ABA7A7;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-1mw0-up-value {
    color: #363636;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-1mw0-up-value-alt {
    color: #363636;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-1mw0-up-pwd-value {
    font-size: 33px;
}
.pg-1mw0-up-add-btn {
    padding-right: 30px;
    width: 16px;
    height: 100%;
    background-image: url("../../../images/icons/pg-1mw0-add-icon.png");
    background-repeat: no-repeat;
    background-position: center center;
}
.pg-1mw0-up-edit-btn {
    padding-right: 30px;
    width: 16px;
    height: 100%;
    background-image: url("../../../images/icons/pg-1mw0-edit-icon.png");
    background-repeat: no-repeat;
    background-position: center center;
}
.pg-1mw0-up-add-btn:focus, .pg-1mw0-up-edit-btn:focus {
    box-shadow: none;
}
.pg-1mw0-up-changepwd-btn {
    padding-top: 5px;
    padding-bottom: 5px;
    height: 26px;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 14px;
    background-color: #1CA9E1;
    border: none;
    border-radius: 5px;
}
.chat-container {
    /* border: 2px solid red; */
    /* position: absolute; */
    width: 100%;
    background-color: transparent;
    height:500px;
    z-index: -1;
    /* bottom:176px */
}

.left-empty-container {
    height: 500px;
    border-right: 2px solid lightgray;
    justify-content: center;
    display:flex;
}
.left-container{
    height: 500px;
    border-right: 2px solid lightgray;
    flex-direction: column-reverse;
    display:flex;
}
.messages-container-wrapper{

    max-height: 500px;
    height:500px;
    overflow-y: scroll;

}
.messages-container{

    max-height: 500px;
    height:500px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
    /* justify-content: flex-end; */
}
.pg-1mw0-uprofile-section {
    background-color: #f8f8f8;
    height:700px;
}


.chat-input-box {
    width:400px;
    margin-left:20px;
    margin-top:10px;
    display: flex;
    position: relative;
    /* border-radius: 8px; */
    max-width:80%;
    border-right: 20px white;
    border-left: 12px solid transparent;
    border-top: 12px solid white;
    border-bottom: 20px solid transparent;
}

.sent-icon-container{
    position: absolute;
    right:0px;
    top:-12px;
    width:47px;
    height:47px;
    background-color: #1AAFE3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-field {
    width:388px;
    border: none;
    padding-bottom: 10px;
    padding-right: 15px;
    padding-left: 15px;
}

.message-sent-icon{
    height: 30px;
    width: 30px;
    float: left;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../../../images/icons/pg-306f-msg-send-btn.png');
}


.message-boox {
    padding: 0px 10px 10px 10px;
    background-color: white;
}

.start-button{
    color:#fff;
    background-color: #1CA9E1;
}

.empty-chat-bg-conatiner{
    height:500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.empty-chat-bg{
    width:150px;

}

.empty-chat-text-container{
    display: flex;
    flex-direction: column;
    opacity:0.4;
    align-items: center;
}

.footer-section{
    /* margin-top:400px; */
}

.pg-hbr4-pghpropselect-o {
    width:300px
}
.select-category-section {
    background-color: #fff;
    padding-top: 30px;
    padding-bottom: 30px;
}

.container .chat {
    align-items: center;
}

.chat .pg-hbr4-pghselect__control {
    border: 1px solid lightgray !important;
    border-radius: 8px !important;
} 

.category-select-img{
    border-radius: 4px;
    width:50px;
    height:auto;
}
.category-option-container {
    display: flex;

    align-items: center;
}
.category-option-title-container{
    display: flex;
    flex-direction: column;
}
.category-option-user-container {
    display: flex;
}
.category-option-title{
    font-weight: bold;
}
.category-option-user{
    font-weight: 100;
    font-size: 12px;
}

.group-box {
    width:100%;
    border : 1.5px solid #1CA9E1;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.group-inner-box {
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.group-title-box {
    display: flex;
    flex-direction: column;
}

.group-image{
    width:40px
}

.end-btn {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height:30px;
    background-color: #1CA9E1;
    color: #FFFFFF;
}

.group-box.disabled {
    border : 1.5px solid lightgray;
}

.group-box.disabled img {
    opacity: 0.2;
}
.group-box.disabled div {
    opacity: 0.8;
}
