/* GLOBAL RESET */

:focus {
    outline: 0;
}

/* IMAGE-SLIDER-OVERRIDE */

.bKOzTU {
    color: #FFF!important;
    margin-left: 0!important;
    margin-top: 0!important;
    width: 39px!important;
    height: 39px!important;
    background-color: #1CA9E1!important;
    top: calc(50% - 21px)!important;
    border-radius: 2px!important;
}
.bKOzTU:before {
    left: 15px!important;
    top: 14px!important;
    border-top: solid 2px #FFF!important;
    border-right: solid 2px #FFF!important;
}
.bKOzTU:focus {
    outline: none;
}
.hUeBZS {
    color: #FFF!important;
    margin-left: 0!important;
    margin-top: 0!important;
    width: 39px!important;
    height: 39px!important;
    background-color: #1CA9E1!important;
    top: calc(50% - 21px)!important;
    border-radius: 2px!important;
}
.hUeBZS:before {
    right: 17px!important;
    top: 14px!important;
    border-top: solid 2px #FFF!important;
    border-right: solid 2px #FFF!important;
}
.hUeBZS:focus {
    outline: none;
}

/* IMAGE-VIDEO-LIGHTBOX OVERRIDE */

.react-spinner-material {
    top: 50%;
    left: 50%;
    position: absolute;
    border: 3px solid #FFF!important;
}

/* COMMON */

.pg-6ef4-sect-heading-row {
    margin-bottom: 30px;
}
.pg-6ef4-section-heading {
    color: #363636;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 25px;
}

/* AD DETAILS SECTION */

.pg-6ef4-ad-det-section {
    padding-top: 25px;
    padding-bottom: 45px;
}
.pg-6ef4-ad-catg-img-wrapper {
    width: 124px;
    display: inline-block;
    vertical-align: top;
}
.pg-6ef4-ad-status {
    margin-top: -15px;
    margin-left: auto;
    margin-right: auto;
    width: 46px;
    height: 19px;
    position: relative;
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 19px;
    text-align: center;
    border: none;
    border-radius: 10px;
}
.pg-6ef4-ad-det-wrapper {
    margin-left: 30px;
    width: calc(100% - 154px);
    display: inline-block;
    vertical-align: top;
}
.pg-6ef4-ad-det-col1 {
    border-right: 1px solid #CECECE;
}
.pg-6ef4-ad-category {
    margin-bottom: 5px;
    color: #A4A4A4;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-6ef4-ad-title {
    margin-bottom: 5px;
    color: #242424;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 35px;
}
.pg-6ef4-ad-location-user-row {
    margin-bottom: 20px;
}
.pg-6ef4-ad-location, .pg-6ef4-ad-by {
    color: #363636;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-6ef4-ad-description {
    height: 46px;
    color: #8B8B8B;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pg-6ef4-ad-description p {
    margin-bottom: 0;
}
.pg-6ef4-ad-tprice-wrapper {
    padding-left: 15px;
}
.pg-6ef4-ad-tprice-label {
    margin-bottom: 5px;
    color: #213333;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-6ef4-ad-tprice-value {
    margin-bottom: 30px;
    color: #242424;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 35px;
}
.pg-6ef4-ad-toggle-controls-row {
    margin-top: 10px;
}
.pg-6ef4-sh-link, .pg-6ef4-sh-link:hover {
    cursor: pointer;
    color: #1CA9E1;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-6ef4-sh-link:hover {
    text-decoration: none;
}


.pg-6ef4-prop-kissan-icon-wrapper {
    width: 39px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}
.pg-6ef4-prop-kissan-lv-wrapper {
    margin-left: 15px;
    display: inline-block;
    vertical-align: middle;
}
.pg-6ef4-prop-kissan-ly-label {
    margin-bottom: 0;
    color: #A4A4A4;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-6ef4-prop-kissan-ly-value {
    margin-bottom: 0;
    color: #213333;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 25px;
}

/* AD VIDEO/IMAGES SECTION */

.pg-6ef4-ad-imgs-section {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #F8F8F8;
}
.pg-6ef4-ad-video-wrapper {
    margin-right: 15px;
    cursor: pointer;
    position: relative;
}
.pg-6ef4-ad-video-wrapper img {
    width: 100%;
    border-radius: 5px;
}
.pg-6ef4-video-play-icon {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    background-image: url("../../images/icons/pg-g83h-video-play-icon.png");
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 5px;
}
.pg-6ef4-ad-img-wrapper {
    margin-right: 15px;
    cursor: pointer;
    /* 
    -webkit-box-shadow: 15px 15px 50px -25px rgba(0,0,0,1);
    -moz-box-shadow: 15px 15px 50px -25px rgba(0,0,0,1);
    box-shadow: 15px 15px 50px -25px rgba(0,0,0,1);
    */
}
.pg-6ef4-ad-img-wrapper img {
    width: 100%;
    border-radius: 5px;
}

/* GROUP DETAILS SECTION */

.pg-6ef4-gd-int-section {
    padding-top: 60px;
    padding-bottom: 60px;
}
.pg-6ef4-gd-users-row {
    margin-bottom: 50px;
}
.pg-6ef4-gd-user-row {
    margin-bottom: 20px;
}
.pg-6ef4-gd-ushare-row {
    margin-bottom: 10px;  
}
.pg-6ef4-gd-ushare {
    color: #363636;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-6ef4-gd-us-pbar {
    height: 19px;
    background-color: #ECECEC;
}
.pg-6ef4-gd-us-pbar-seek {
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 19px;
    text-align: center;
    background-color: #1AAFE3;
    border-right: 5px solid #FFF;
}
.pg-6ef4-int-btn {
    min-width: 137px;
}
.pg-6ef4-int-btn:hover {
    text-decoration: none;
}

/* YIELD PROPORTION SECTION (KISSAN ONLY) */

.pg-6ef4-yield-prop-section {
    padding-top: 60px;
    padding-bottom: 60px;
}
.pg-6ef4-sh-slider-row {
    margin-bottom: 40px;
}

.pg-6ef4-mshare-tshare-row {
    margin-bottom: 30px;
}

.pg-6ef4-mshare-wrapper,
.pg-6ef4-oshare-wrapper {
    padding: 20px;
    border-radius: 5px;
}
.pg-6ef4-mshare-heading,
.pg-6ef4-oshare-heading {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}

.pg-6ef4-mshare-wrapper {
    background-color: #1AAFE3;
}
.pg-6ef4-mshare-heading {
    padding-bottom: 10px;
    color: #FFF;
    border-bottom: 1px solid #4FB9E4;
}
.pg-6ef4-mshare-row {
    margin-top: 10px;
}
.pg-6ef4-mshare-form-label {
    margin-bottom: 0;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-6ef4-mshare-igroup .input-group-text {
    color: #324444;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 25px;
    background-color: #FFF;
    border-radius: 5px;
}
.pg-6ef4-mshare-igroup .pg-6ef4-mshare-igt-yield {
    padding: .340rem .75rem;
    font-size: 14px;
}
.pg-6ef4-mshare-textbox {
    height: 38px;
    color: #363636;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
    background-color: #FFF;
    border-radius: 5px;
}
.pg-6ef4-mshare-igroup .pg-6ef4-mshare-textbox {
    padding-left: 0;
    border-left: 0;
}
.pg-6ef4-mshare-igroup .pg-6ef4-mshare-textbox-yield {
    border-right: 0;
}

.pg-6ef4-oshare-wrapper {
    background-color: #F8F8F8;
}
.pg-6ef4-oshare-heading {
    padding-bottom: 10px;
    color: #363636;
    border-bottom: 1px solid #D6E8E8;
}
.pg-6ef4-oshare-row {
    margin-top: 25px;
}
.pg-6ef4-icon-wrapper {
    display: inline-block;
    vertical-align: middle;
}
.pg-6ef4-lv-wrapper {
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
}
.pg-6ef4-oshare-label {
    margin-bottom: 0;
    color: #ABA7A7;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-6ef4-oshare-value {
    margin-bottom: 0;
    color: #363636;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}

.pg-6ef4-sh-slider-label {
    color: #363636;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 25px;
}
.pg-6ef4-sh-slider-value {
    color: #363636;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 25px;
}

/* RANGE SLIDER OVERRIDE */

.rangesilder-values {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.rangeslider-current {
    color: #242424;
}
.rangeslider-balance {
    color: #8F8F8F;
}

.rangeslider {
    margin: 10px 0;
    position: relative;
    background-color: #53CF1C;
    border-radius: 39px;
    touch-action: none;
}
.rangeslider,
.rangeslider .rangeslider__fill {
    display: block;
}
.rangeslider .rangeslider__handle {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    background-color: #FFF;
    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.4), 10px 10px 50px rgba(0, 0, 0, 0.4);
    outline: none;
}
.rangeslider .rangeslider__handle .rangeslider__active {
    opacity: 1;
}
.rangeslider .rangeslider__handle-tooltip {
    width: 40px;
    height: 40px;
    left: 50%;
    display: inline-block;
    position: absolute;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    transition: all 100ms ease-in;
    transform: translate3d(-50%, 0, 0);
}
.rangeslider .rangeslider__handle-tooltip span {
    margin-top: 12px;
    display: inline-block;
    line-height: 100%;
}
.rangeslider .rangeslider__handle-tooltip:after {
    width: 0;
    height: 0;
    position: absolute;
    content: ' ';
}

/**
* Rangeslider - Horizontal slider
*/
.rangeslider-horizontal {
    height: 25px;
}
.rangeslider-horizontal .rangeslider__fill {
    height: 100%;
    background-color: #1AAFE3;
    border-radius: 39px;
}
.rangeslider-horizontal .rangeslider__handle {
    width: 39px;
    height: 39px;
    top: 50%;
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0);
}
.rangeslider-horizontal .rangeslider__handle-tooltip {
    top: -55px;
}
.rangeslider-horizontal .rangeslider__handle-tooltip:after {
    left: 50%;
    bottom: -8px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid rgba(0, 0, 0, 0.8);
    transform: translate3d(-50%, 0, 0);
}

/**
* Rangeslider - Labels
*/
.rangeslider__labels {
    position: relative;
}
.rangeslider__labels .rangeslider__label-item {
    top: 10px;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
    transform: translate3d(-50%, 0, 0);
}

/* DESCRIPTION SECTION */

.pg-6ef4-desc-section {
    padding-top: 50px;
    padding-bottom: 190px;
    background-color: #F8F8F8;
    background-image: url("../../images/bgs/footer-1-bg.png");
    background-repeat: repeat-x;
    background-position: center bottom;
}
.pg-6ef4-desc {
    color: #8B8B8B;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 22px;
}

/* SUCCESS MODAL */

.pg-6ef4-tnclink {
    color: #1CA9E1;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 18px;
}

.mobile {
    display: none
}

@media only screen and (max-width: 480px) {
.no-mobile{
    display: none;
}
.mobile {
    display: flex !important
}
.pg-6ef4-ad-catg-img-wrapper{
    justify-content: center;
    text-align: center;
}
.pg-6ef4-ad-tprice-wrapper{
 padding-left: 0 !important;
 margin-top: 20px;   
}
.pg-6ef4-int-btn {
    width:100% !important
}
}