/* PROGRESS TRACKER */

.progtrckr {
    margin: 0 auto;
    margin-top: 75px;
    width: 60%;
    display: table;
    position: relative;
}
.progtrckr-row {
    display: table-row;
}
.progtrckr-step {
    display: table-cell;
    position: relative;
    text-align: center;
}
.progtrckr-step:before,
.progtrckr-step:after {
    width: 49.5%;
    height: 4px;
    top: 12%;
    position: absolute;
    z-index: -1;
    content : " ";
}
.progtrckr-step:before {
    right: 50%;
}
.progtrckr-step:after {
    left: 50%;
}
.progtrckr-step:first-child:before,
.progtrckr-step:last-child:after {
    background-color: #FFF;
}
.progtrckr-step .step-circle {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: #CCEEEE;
    border: 2px solid #FFF;
    border-radius: 50%;
}
.progtrckr-step p {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 25px;
}

.progtrckr-todo__2rCwX:before,
.progtrckr-todo__2rCwX:after {
    background-color: #CCEEEE;
}
.progtrckr-todo__2rCwX .step-circle {
    background-color: #CCEEEE;
}
.progtrckr-todo__2rCwX p {
    color: #C5C5C5;
}
.progtrckr-doing__3GALV:before,
.progtrckr-doing__3GALV:after {
    background-color: #4DD22C;
}
.progtrckr-doing__3GALV .step-circle {
    background-color: #4DD22C;
}
.progtrckr-doing__3GALV p {
    color: #363636;
}
.progtrckr-done__c0N9Q:before,
.progtrckr-done__c0N9Q:after {
    background-color: #1AAFE3;
}
.progtrckr-done__c0N9Q .step-circle {
    background-color: #1AAFE3;
}
.progtrckr-done__c0N9Q p {
    color: #363636;
}

/* REACT-SELECT OVERRIDE */

.pg-ber9-apfselect__control {
    background-color: #FFF!important;
    border: 1px solid #CED4DA!important;
    border-radius: 5px!important;
}
.pg-ber9-apfselect__control--is-focused {
    border: 1px solid #CED4DA!important;
    box-shadow: none!important;
}
.pg-ber9-apfselect__single-value {
    color: #363636!important;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-ber9-apfselect__menu {
    z-index: 800!important;
}
.pg-ber9-apfselect__menu-list {
    padding-left: 5px;
    padding-right: 5px;
    color: #363636!important;
    font-size: 14px;
    font-weight: 400;
    font-family: Poppins, Arial;
}
.pg-ber9-apfselect__option {
    padding: 2px 12px;
}
.pg-ber9-apfselect__option--is-focused {
    cursor: pointer!important;
    color: #363636!important;
    background-color: #FFF!important;
}
.pg-ber9-apfselect__option--is-selected {
    color: #FFF!important;
    background-color: rgba(164, 164, 164, 0.5)!important;
}

/* STEP 4 */

.pg-ber9-s4-calendar-row {
    margin-top: 50px;
}

/* MODAL POPUP */

.pg-ber9-tnclink {
    margin: 0 auto;
    color: #1CA9E1;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 18px;
    background-color: #FFF;
    border: none;
}
.pg-ber9-tnclink:focus {
    outline: none;
}

@media only screen and (max-width: 480px) {
    .add-post-heading-row{
        text-align: center;
    }
    .progtrckr {
        width:100% !important;
    }
}
