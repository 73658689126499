/* GLOBAL RESET */

:focus {
    outline: 0;
}

/* REACT-SELECT OVERRIDE */

.pg-g83h-pselect__control {
    background-color: #FFF!important;
    border: 1px solid #BEBEBE!important;
    border-radius: 5px!important;
}
.pg-g83h-pselect__control--is-focused {
    border: 1px solid #BEBEBE!important;
    box-shadow: none!important;
}
.pg-g83h-pselect__single-value {
    color: #363636!important;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-g83h-pselect__menu {
    z-index: 800!important;
}
.pg-g83h-pselect__menu-list {
    padding-left: 5px;
    padding-right: 5px;
    color: #363636!important;
    font-size: 14px;
    font-weight: 400;
    font-family: Poppins, Arial;
}
.pg-g83h-pselect__option {
    padding: 2px 12px;
}
.pg-g83h-pselect__option--is-focused {
    cursor: pointer!important;
    color: #363636!important;
    background-color: #FFF!important;
}
.pg-g83h-pselect__option--is-selected {
    color: #FFF!important;
    background-color: rgba(164, 164, 164, 0.5)!important;
}

/* DAY-PICKER OVERRIDE */

.DayPicker {
    display: block;
}
.DayPicker-NavButton {
    top: 0.9em;
}
.DayPicker-NavButton--prev {
    left: 0;
    right: auto;
}
.DayPicker-NavButton--next {
    left: auto;
    right: 0;
}
.DayPicker-Month {
    margin: 0 auto;
    padding: 5px;
    width: 33.33333333%;
    display: block;
    border-collapse: separate;
    border-spacing: 17px;
}
.DayPicker-Caption {
    margin-bottom: 20px;
    padding: 15px 0.5em;
    display: block;
    text-align: center;
    border-top: 1px solid #DEDEDE;
    border-bottom: 1px solid #DEDEDE;
}
.DayPicker-Caption > div {
    color: #363636;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.DayPicker-Weekday {
    color: #AEAEAE;
    font-size: 12px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 18px;
}
.DayPicker-Day {
    padding: 7px 8px;
    color: #363636;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 17px;
}
.DayPicker-Day--selected .DayPicker-Day--disabled:not(.DayPicker-Day--outside) {
    position: relative;
    color: #FFF;
    background-color: #1CA9E1;
}
.DayPicker-Day--selected .DayPicker-Day--disabled:not(.DayPicker-Day--outside):hover {
    background-color: #1CA9E1;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    color: #FFF;
    background-color: #1CA9E1;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #1CA9E1;
}
.DayPicker-Day--requested {
    color: #FFF;
    background-color: #37B837;
}
.DayPicker-Day--requested:hover {
    background-color: #37B837!important;
}
.DayPicker-Day--rejected {
    color: #363636;
    background-color: #AAA4A4;
}
.DayPicker-Day--rejected:hover {
    background-color: #AAA4A4!important;
}
.DayPicker-Day--outside:hover {
    background-color: #FFF!important;
}

/* COMMON */

.pg-g83h-sect-heading-row {
    margin-bottom: 30px;
}
.pg-g83h-section-heading {
    color: #363636;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 25px;
}

/* BACK LINK SECTION */

.pg-g83h-blink-section {
    /* border-top: 1px solid #ECECEC; */
}

/* AD DETAILS SECTION */

.pg-g83h-ad-det-section {
    padding-top: 20px;
    padding-bottom: 35px;
}
.pg-g83h-ad-det-col1 {
    border-right: 1px solid #CECECE;
}
.pg-g83h-ad-catg-img-wrapper {
    width: 124px;
    display: inline-block;
    vertical-align: top;
}
.pg-g83h-ad-status {
    margin-top: -15px;
    margin-left: auto;
    margin-right: auto;
    width: 46px;
    height: 19px;
    position: relative;
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 19px;
    text-align: center;
    border: none;
    border-radius: 10px;
}
.pg-g83h-ad-det-wrapper {
    margin-left: 30px;
    width: calc(100% - 154px);
    display: inline-block;
    vertical-align: top;
}
.pg-g83h-ad-category {
    margin-bottom: 5px;
    color: #A4A4A4;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-g83h-ad-title {
    margin-bottom: 5px;
    color: #242424;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 35px;
}
.pg-g83h-lu-row {
    margin-bottom: 10px;
}
.pg-g83h-ad-location, .pg-g83h-ad-by {
    color: #363636;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-g83h-sh-link {
    cursor: pointer;
    color: #1CA9E1;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-g83h-ad-tprice-wrapper {
    padding-left: 15px;
}
.pg-g83h-ad-tprice-label {
    margin-bottom: 10px;
    color: #213333;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-g83h-ad-tprice-value {
    margin-bottom: 0;
    color: #242424;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 35px;
}

.pg-g83h-ad-specs-row {
    margin-top: 20px;
}
.pg-g83h-ad-toggle-controls-row {
    margin-top: 10px;
}

.pg-g83h-desc-section,
.pg-g83h-ad-imgs-section {
    padding-top: 10px;
}

/* DESCRIPTION SECTION */

.pg-g83h-desc-section {
    padding-bottom: 40px;
    background-color: #FFF;
}
.pg-g83h-desc {
    color: #8B8B8B;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 22px;
}

/* AD VIDEO/IMAGES SECTION */

.pg-g83h-ad-imgs-section {
    padding-bottom: 40px;
    background-color: #FFF;
}
.pg-g83h-ad-video-wrapper {
    margin-right: 15px;
    cursor: pointer;
    position: relative;
}
.pg-g83h-ad-video-wrapper img {
    width: 100%;
    border-radius: 5px;
}
.pg-g83h-video-play-icon {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    background-image: url("../../../images/icons/pg-g83h-video-play-icon.png");
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 5px;
}
.pg-g83h-ad-img-wrapper {
    margin-right: 15px;
    cursor: pointer;
    /* 
    -webkit-box-shadow: 15px 15px 50px -25px rgba(0,0,0,1);
    -moz-box-shadow: 15px 15px 50px -25px rgba(0,0,0,1);
    box-shadow: 15px 15px 50px -25px rgba(0,0,0,1);
    */
}
.pg-g83h-ad-img-wrapper img {
    width: 100%;
    border-radius: 5px;
}

/* GROUP DETAILS SECTION */

.pg-g83h-grp-det-section {
    padding-top: 30px;
    padding-bottom: 25px;
    background-color: #F8F8F8;
}
.pg-g83h-currentuser-row {
    margin-bottom: 25px;
    padding: 30px 35px;
    background-color: #1CA9E1;
    border-radius: 5px;
}
.pg-g83h-cuser-wrapper {
    border-right: 1px solid #E6E6E6;
}
.pg-g83h-currentuser-text {
    margin-left: 10px;
    color: #FFF;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 27px;
}
.pg-g83h-icon-wrapper {
    display: inline-block;
    vertical-align: middle;
}
.pg-g83h-lv-wrapper {
    margin-left: 15px;
    display: inline-block;
    vertical-align: middle;
}
.pg-g83h-currentuser-label {
    margin-bottom: 0;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-g83h-currentuser-value {
    margin-bottom: 0;
    color: #FFF;
    font-size: 26px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 39px;
}
.pg-g83h-relieve-btn {
    padding: 8px 20px;
    min-width: 137px;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 21px;
    background-color: #1CA9E1;
    border: 1px solid #FFF;
    border-radius: 5px;
}

.pg-g83h-user-det-wrapper {
    margin-bottom: 20px;
    padding: 25px 20px;
    background-color: #FFF;
    border-radius: 5px;
}
.pg-g83h-guser-status-icons {
    top: 15px;
    right: 15px;
    position: absolute;
}
.pg-g83h-guser-holdericon {
    margin-top: 1px;
    margin-right: 15px;
    width: 20px;
    height: 13px;
    display: inline-block;
    position: relative;
    float: right;
    background-image: url("../../../images/icons/pg-g83h-current-holder.png");
    background-repeat: no-repeat;
    background-position: center center;
}
.pg-g83h-guser-genuineusericon {
    margin-right: 15px;
    width: 11px;
    height: 14px;
    display: inline-block;
    position: relative;
    float: right;
    background-image: url("../../../images/icons/pg-g83h-genuine-user.png");
    background-repeat: no-repeat;
    background-position: center center;
}
.pg-g83h-guser-name {
    padding-top: 10px;
    padding-bottom: 12px;
    color: #363636;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 21px;
    text-align: center;
    border-bottom: 1px solid #D6E8E8;
}
.pg-g83h-guser-spec-row {
    margin-top: 20px;
}
.pg-g83h-nogroupdet-row {
    margin: 20px auto;
}
.pg-g83h-nogroupdet-msg {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 22px;
    text-align: center;
}

/* CALENDAR/DAY PICKER SECTION */

.pg-g83h-daypicker-section {
    padding-top: 30px;
    padding-bottom: 30px;
}

.pg-g83h-section-heading-history {
    color: #AAA4A4;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}

.pg-g83h-tcycle-row {
    margin-top: 10px;
    overflow: hidden;
}
.pg-g83h-tcycle-col {
    padding-left: 5px!important;
    padding-right: 5px!important;
}
.pg-g83h-tcycle-col:first-child {
    padding-left: 0!important;
}
.pg-g83h-tcycle-col:last-child {
    padding-right: 0!important;
}
.pg-g83h-tcycle {
    padding: 8px 0;
    color: #AAA4A4;
    font-family: 'Poppins', Arial;
    text-align: center;
    background-color: #F8F8F8;
}
.pg-g83h-tcycle-active {
    color: #FFF;
    background-color: #37B837;
}
.pg-g83h-tcycle-active:after {
    margin: 0 auto;
    width: 0;
    height: 0;
    left: 0;
    right: 0;
    bottom: -15px;
    position: absolute;
    content: '';
    border-top: 15px solid #37B837;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
}
.pg-g83h-tcycle-name {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
}
.pg-g83h-tcycle-duration {
    display: block;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}

.pg-g83h-cal-heading-row {
    margin-top: 30px;
}
.pg-g83h-partner-ava-lv {
    color: #363636;
    font-size: 14px;
    font-family: 'Poppins', Arial;
    line-height: 21px;
    text-align: right;
}
.pg-g83h-partner-ava-label {
    font-weight: 400;
}
.pg-g83h-partner-ava-value {
    font-weight: 500;
}

.pg-g83h-cal-row {
    margin-top: 20px;
}

/* BENEFIT DETAILS SECTION (KISSAN) */

.pg-g83h-benefit-det-section {
    padding-top: 45px;
    padding-bottom: 55px;
}

/* DOCUMENTS SECTION */

.pg-g83h-docs-section {
    padding-top: 30px;
    padding-bottom: 190px;
    background-color: #F8F8F8;
    background-image: url("../../../images/bgs/footer-1-bg.png");
    background-repeat: repeat-x;
    background-position: center bottom;
}
.pg-g83h-docs-section-alt {
    background-color: #FFF;
    background-image: url("../../../images/bgs/footer-2-bg.png");
}
.pg-g83h-prop-doc-col {
    margin-bottom: 10px;
}
.pg-g83h-prop-doc {
    display: block;
    color: #1CA9E1;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-g83h-prop-doc:hover {
    color: #1CA9E1;
    background-image: url("../../../images/icons/pg-g83h-doc-download.png");
    background-repeat: no-repeat;
    background-position: right center;
}

/* REQUEST MODAL */

.pg-g83h-modal-form-label {
    color: #324444;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-g83h-reqday-igroup .pg-g83h-textbox-reqday {
    color: #363636;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
    background-color: #FFF;
    border-right: 0;
}
.pg-g83h-reqday-igroup .input-group-text {
    padding: .340rem 1.10rem;
    color: #7E7B7B;
    font-size: 15px;
    background-color: #FFF;
    border-radius: 5px;
}

/* ACCEPT/REJECT MODAL */

.pg-g83h-req-user-row {
    padding: 15px 0;
    border-bottom: 1px solid #DEDFDE;
}
.pg-g83h-req-user-row:last-child {
    padding: 15px 0 0 0;
    border-bottom: none;
}
.pg-g83h-req-uact-row {
    cursor: pointer;
}
.pg-g83h-aorr-text {
    color: #A4A4A4;
}