.left-bubble-container {
    /* background-color: red; */
    display:inline-block;
    border-right: 0px solid transparent;
    border-left: 18px solid transparent;
    border-top: 12px solid #fff;
    position: relative;
    margin-top:4px;
    margin-bottom: 4px;
    max-width:70%;
}

.left-message-box {
    padding:5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top:-12px;
    left:0px;


    background-color: #fff;
}