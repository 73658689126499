/* REACT-SELECT OVERRIDE */

.pg-xxxx-nsselect-o {
    width: calc(100% - 39px);
    height: 30px;
    border-right: 1px solid #CECECE;
}
.pg-xxxx-nsselect-o .pg-xxxx-nsselect__control {
    min-height: 30px!important;
}
.pg-xxxx-nsselect-o .pg-xxxx-nsselect__value-container,
.pg-xxxx-nsselect-o .pg-xxxx-nsselect__indicators {
    height: 100%;
}

.pg-xxxx-asselect-o {
    height: 100%;
    width: calc(100% - 39px);
    float: left;
}
.pg-xxxx-asselect-o .pg-xxxx-nsselect__control {
    width: calc(100% - 5px);
}

.pg-xxxx-nsselect__control {
    height: 100%;
    background-color: #FFF!important;
    border: none!important;
    border-radius: 0!important;
}
.pg-xxxx-nsselect__control--is-focused {
    border: none!important;
    box-shadow: none!important;
}
.pg-xxxx-nsselect__single-value {
    color: #363636!important;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-xxxx-nsselect__menu {
    z-index: 800!important;
}
.pg-xxxx-nsselect__menu-list {
    padding-left: 5px;
    padding-right: 5px;
    color: #363636!important;
    font-size: 14px;
    font-weight: 400;
    font-family: Poppins, Arial;
}
.pg-xxxx-nsselect__option {
    padding: 2px 12px;
}
.pg-xxxx-nsselect__option--is-focused {
    cursor: pointer!important;
    color: #363636!important;
    background-color: #FFF!important;
}
.pg-xxxx-nsselect__option--is-selected {
    color: #FFF!important;
    background-color: rgba(164, 164, 164, 0.5)!important;
}


.pg-xxxx-search-banner {
    /* height: 168px; */
    background-image: url("../images/banners/properties-banner.png");
    background-color: #242424;
    background-repeat: repeat;
    background-position: center;
}
.pg-xxxx-search-row {
    padding-top: 25px;
    padding-bottom: 25px;
    min-height: 168px;
}
.pg-xxxx-search-bar {
    margin-bottom: 10px;
    height: 42px;
    background-color: #FFF;
    border: none;
    border-radius: 5px;
}
.pg-xxxx-psb-category-wrapper {
    width: 30%!important;
    display: flex;
    align-items: center;
}
.pg-xxxx-ns-category-icon {
    margin-left: 20px;
    margin-right: 5px;
    width: 14px;
    height: 14px;
    background-image: url("../images/icons/category.png");
    background-position: center;
    background-repeat: no-repeat;
}
.pg-xxxx-ns-input-group {
    height: 100%;
    align-items: center;
}
.pg-xxxx-ns-input-group-text {
    padding: 0;
    background-color: #FFF;
    border: none;
    border-radius: 0;
}
.pg-xxxx-ns-search-icon {
    margin-left: 10px;
    margin-right: 15px;
    width: 22px;
    height: 22px;
    background-image: url("../images/icons/search.png");
    background-position: center;
    background-repeat: no-repeat;
}
.header-category-icon {
    margin-left: 20px;
    margin-right: 5px;
    width: 28px;
    height: 28px;
    background-image: url("../images/icons/category.png");
    background-position: center;
    background-repeat: no-repeat;
}
.header-location-icon {
    margin-left: 20px;
    margin-right: 5px;
    width: 28px;
    height: 28px;
    background-image: url("../images/icons/ad-location.png");
    background-position: center;
    background-repeat: no-repeat;
}
.pg-xxxx-ns-search-value {
    padding-left: 0;
    height: 25px;
    color: #242424;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 25px;
    border: none;
    outline: none;
}

.pg-xxxx-psb-search-btn-wrapper {
    width: 20%!important;
}
.pg-xxxx-properties-search-btn {
    padding: 0;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 42px;
    background-color: #1CA9E1;
    border: 0;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.pg-xxxx-adv-search-row {
    margin-bottom: 10px;
    height: 42px;
    background-color: #FFF;
    border-radius: 5px;
}
.pg-xxxx-adv-search-row2 {
    margin-top: 10px;
}
.pg-xxxx-adv-search-radiobtn {
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
}

/* SET 1 */

/*.pg-xxxx-form-check-ih {
    left: -9999px;
    position: absolute;
}
.pg-xxxx-form-check-ih + label {
    width: 20px;
    height: 20px;
    border: 1.5px solid #FFF;
    border-radius: 5px;
    transition: 500ms all;
}
.pg-xxxx-form-check-ih:checked + label {
    border: 1.5px solid #1CA9E1;
}*/

.pg-xxxx-search-type-link {
    cursor: pointer;
    color: #1CA9E1;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 20px;
}

.mobile, .mobile-block{
    display: none !important;
}


@media only screen and (min-width: 480px) {
    .mobile-signin{
        display: none !important;
    }
}

@media only screen and (max-width: 480px) {
    .no-mobile {
        display : none;
    }
    .mobile {
        display: flex;
    }
    .mobile-block {
        display: block;
    }
    .search-container-row{
        /* margin-top:-50px; */
        margin-right:10px;
        margin-left:10px;
        min-height: auto;
        height: auto;
    }
    .search-container-col {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        
    }
    .search-box{
        margin-top:10px;
        flex: 0 0 100% !important;
        max-width: 100% !important;
        background-color: #fff;
        height:42px !important;
        border-radius: 5px !important;
    }

    .search-box span, button{
        border-radius: 5px !important;
    }

    .pg-xxxx-search-bar {
        height: auto;
        background-color: transparent !important;
    }
    .pg-xxxx-search-banner-searchmode {
        height:350px !important;
    }

    .navbar-mobile-icons {

        align-self: right;
        justify-content: right !important;
        display: flex;
        flex-direction: row-reverse;
        flex:1;
    }
    .mobile-signin{
        width:100% !important;
        text-align: center;
    }
}
