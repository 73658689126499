html {
    height: 100%;
}
body {
    height: 100%;
    overflow-x: hidden;
}

/* Bootstrap Overrides */

@media (min-width: 576px) {
    .modal-sm {
        max-width: 350px;
    }
}

#root {
    height: 100%;
}

.navbar {
    padding-top: .75rem;
}
.navbar-brand {
    /* margin-right: 1.5rem; */
}
.navbar-logo {
    height: 33px;
}
.nav-link:focus,
.nav-link:hover {
    color: #212529;
}

.category-dropdown-item {
    color: #636363;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
}
.right-nav-item {
    margin-left: 15px;
}

.ph-notification-count {
    width: 22px;
    height: 22px;
    top: 0;
    right: -5px;
    position: absolute;
    color: #FFF;
    font-size: 11px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 22px;
    text-align: center;
    background-color: #E24D4D;
    border: none;
    border-radius: 50%;
}

#ph-user-links::after {
    content: none;
}
.ph-user-links-menu {
    right: 0;
    left: auto;
}
.ph-user-link {
    padding: .50rem 1.25rem;
    cursor: pointer;
}
.ph-user-link:hover, .ph-user-link:active {
    color: #FFF;
    background-color: #A4A4A4;
}
.ph-user-link-icon {
    margin-right: 15px;
    width: 20px;
    height: 22px;
    float: left;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
}
.ph-ul-home-icon {
    background-image: url('images/icons/ph-home-icon.png');
}
.ph-ul-profile-icon {
    background-image: url('images/icons/ph-profile-icon.png');
}
.ph-ul-aanshik-icon {
    background-image: url('images/icons/ph-aanshik-icon.png');
}
.ph-ul-kissan-icon {
    background-image: url('images/icons/ph-kissan-icon.png');
}
.ph-ul-logout-icon {
    background-image: url('images/icons/ph-logout-icon.png');
}
.ph-user-link-text {
    color: #242424;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
}

.modal-header .close {
    font-size: 25px;
    font-weight: 300;
    line-height: 1;
    color: #B9B9B9;
    opacity: 1;
    outline: none;
}
.modal-header {
    padding-bottom: 0;
    border-bottom: none;
}
.modal-body {
    padding: 2rem;
    padding-top: 1rem;
}
.modal-logo {
    width: 156px;
}
.pg-xxxx-mform-error {
    color: #FF0000;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
    text-align: center;
}
.pg-xxxx-mform-group {
    margin-bottom: 0.5rem;
}
.pg-xxxx-social-btn {
    cursor: pointer;
}
.modal-form-label {
    color: #363636;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
}
.modal-form-control {
    height: 41px;
    color: #363636;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
    background-color: #FFF;
    border: 1px solid #CECACA;
    border-radius: 5px;
}
.votpdigit {
    width: 66px;
    height: 66px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    line-height: 27px;
}
.forgot-pass-link {
    cursor: pointer;
    color: #1CA9E1;
    font-size: 13px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    line-height: 20px;
}
.btn-signin {
    height: 40px;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
    background-color: #1CA9E1;
    border: none;
    border-radius: 5px;
}
.or-text {
    color: #AAAAAA;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
}
.signup-text1 {
    color: #363636;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
}
.signup-text2 {
    cursor: pointer;
    color: #1CA9E1;
    font-size: 13px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
}
.btn-resendotp {
    height: 32px;
    color: #363636;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
    background-color: #F2F2F2;
    border: none;
    border-radius: 5px;
}
.otpveri-status {
    color: #1CA9E1;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    line-height: 35px;
}
.otpveri-message {
    color: #656363;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 22px;
}
.btn-done {
    padding-left: 20px;
    padding-right: 20px;
    line-height: 28px;
}

/* REACT-SELECT OVERRIDE */

.pg-hbr4-pghcatselect-o {
    width: 160px;
}
.pg-hbr4-pghlocselect-o {
    width: 200px;
}

.pg-hbr4-pghselect__control {
    height: 100%;
    background-color: #FFF!important;
    border: none!important;
    border-radius: 0!important;
}
.pg-hbr4-pghselect__control--is-focused {
    border: none!important;
    box-shadow: none!important;
}
.pg-hbr4-pghselect__placeholder,
.pg-hbr4-pghselect__single-value {
    color: #363636!important;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
}
.pg-hbr4-pghselect__menu {
    z-index: 800!important;
}
.pg-hbr4-pghselect__menu-list {
    padding-left: 5px;
    padding-right: 5px;
    color: #363636!important;
    font-size: 14px;
    font-weight: 400;
    font-family: Poppins, Arial;
}
.pg-hbr4-pghselect__option {
    padding: 2px 12px;
}
.pg-hbr4-pghselect__option--is-focused {
    cursor: pointer!important;
    color: #363636!important;
    background-color: #FFF!important;
}
.pg-hbr4-pghselect__option--is-selected {
    color: #FFF!important;
    background-color: rgba(164, 164, 164, 0.5)!important;
}

/* Nav */

.nav-item-separator {
    margin-top: 7px;
    padding-right: 10px;
}
.nav-link-style {
    position: relative;
    color: #636363;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
}
.fa-bell-o,
.fa-user-o {
    font-size: 15px;
}
.badge-info {
    background-color: #E24D4D;
}
.btn:focus {
    outline: 0;
    box-shadow: none;
}
.add-post-btn {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
    background-color: #1CA9E1;
}
.add-post-btn-mobile {
    display: none;
}
.add-post-btn > .fa-plus {
    margin-right: 10px;
}

/* HOME-BANNER */

.pg-hbr4-home-banner {
    height: 550px;
}
.pg-hbr4-banner-search-wrapper {
    height: 100%;
    position: relative;
}

.pg-hbr4-banner-wrapper {
    width: 100%;
    height: 100%;
    top: 0;
    left: auto;
    right: auto;
    position: absolute;
    z-index: 500;
    background-color: rgba(36, 36, 36, 1);
}
.home-banner-left {
    height: 100%;
    background-image: url("images/banners/home-banner-left-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.home-banner-right {
    height: 100%;
    background-image: url("images/banners/home-banner-right-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.pg-hbr4-cap-search-wrapper {
    width: 100%;
    height: 100%;
    top: 0;
    left: auto;
    right: auto;
    position: absolute;
    z-index: 501;
}
.home-banner-left-content {
    color: #1CA9E1;
    font-size: 40px;
    font-weight: 300;
    font-family: 'Inter', sans-serif;
    line-height: 45px;
}



.home-banner-row-1 {
    width: 100%;
    background-color: rgba(36, 36, 36, 1);
}

.search-caption {
    color: #FFF;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    line-height: 35px;
}

.normal-search-row {
    min-height: 49px;
    /* background-color: #FFF; */
    /* -webkit-border-radius: 5px;
    /* -moz-border-radius: 5px; */
    /* border-radius: 5px;  */
}
.ns-category-icon {
    margin-left: 20px;
    margin-right: 5px;
    width: 14px;
    height: 14px;
    background-image: url("images/icons/category.png");
    background-position: center;
    background-repeat: no-repeat;
}

/* REACT-SELECT OVERRIDE */

.pg-hbr4-nsselect-o {
    margin-top: 10px;
    width: calc(100% - 39px);
    height: 30px;
    float: left;
    /* border-right: 1px solid #CECECE; */
}
.pg-hbr4-nsselect-o .pg-hbr4-nsselect__control {
    min-height: 30px!important;
}
.pg-hbr4-nsselect-o .pg-hbr4-nsselect__value-container,
.pg-hbr4-nsselect-o .pg-hbr4-nsselect__indicators {
    height: 100%;
}
.pg-hbr4-nsselect-o .pg-hbr4-nsselect__menu-list {
    max-height: 180px;
}

.pg-hbr4-ascatselect-o, .pg-hbr4-asselect-o {
    height: 100%;
    width: calc(100% - 39px);
    float: left;
}
.pg-hbr4-ascatselect-o .pg-hbr4-nsselect__control,
.pg-hbr4-asselect-o .pg-hbr4-nsselect__control {
    width: calc(100% - 5px);
}
.pg-hbr4-asselect-o .pg-hbr4-nsselect__menu-list {
    max-height: 170px;
}

.pg-hbr4-nsselect__control {
    height: 100%;
    background-color: #FFF!important;
    border: none!important;
    /* border-radius: 0!important; */
}
.pg-hbr4-nsselect__control--is-focused {
    border: none!important;
    box-shadow: none!important;
}
.pg-hbr4-nsselect__placeholder,
.pg-hbr4-nsselect__single-value {
    color: #363636!important;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
}
.pg-hbr4-nsselect__menu {
    z-index: 800!important;
}
.pg-hbr4-nsselect__menu-list {
    padding-left: 5px;
    padding-right: 5px;
    color: #363636!important;
    font-size: 14px;
    font-weight: 400;
    font-family: Poppins, Arial;
}
.pg-hbr4-nsselect__option {
    padding: 2px 12px;
}
.pg-hbr4-nsselect__option--is-focused {
    cursor: pointer!important;
    color: #363636!important;
    background-color: #FFF!important;
}
.pg-hbr4-nsselect__option--is-selected {
    color: #FFF!important;
    background-color: rgba(164, 164, 164, 0.5)!important;
}


.ns-input-group {
    height: 100%;
}
.ns-search-icon {
    margin-left: 10px;
    margin-right: 15px;
    width: 22px;
    height: 22px;
    background-image: url("images/icons/search.png");
    background-position: center;
    background-repeat: no-repeat;
}
.ns-input-group-text {
    padding: 0;
    background-color: #FFF;
    border: none;
    border-radius: 0;
}
.ns-search-value {
    margin-top: 10px;
    padding-left: 0;
    height: 30px;
    color: #242424;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 27px;
    border: none;
    outline: none;
}
.ns-search-value:focus {
    box-shadow: none;
}

.adv-search-row {
    margin-bottom: 10px;
    height: 42px;
    background-color: #FFF;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.adv-search-row2 {
    margin-top: 15px;
    /*height: 42px;*/
}
.adv-search-price-text {
    color: #FFF;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 20px;
}
.adv-search-slider-row {
    margin-top: 15px;
    margin-bottom: 15px;
}
.adv-search-price-value {
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
}
.adv-category-icon {
    margin-left: 20px;
    margin-right: 5px;
    width: 14px;
    height: 14px;
    background-image: url("images/icons/category.png");
    background-position: center;
    background-repeat: no-repeat;
}
.adv-input-group {
    height: 100%;
}
.adv-search-icon {
    margin-left: 16px;
    margin-right: 12px;
    width: 22px;
    height: 22px;
    background-image: url("images/icons/search.png");
    background-position: center;
    background-repeat: no-repeat;
}
.adv-input-group-text {
    padding: 0;
    background-color: #FFF;
    border: none;
}
.adv-search-value {
    margin-top: 8px;
    padding-left: 0;
    height: 30px;
    color: #242424;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 27px;
    border: none;
    outline: none;
}
.adv-search-value:focus {
    box-shadow: none;
}
.adv-location-icon {
    margin-left: 20px;
    margin-right: 5px;
    width: 14px;
    height: 14px;
    background-image: url("images/icons/ad-location.png");
    background-position: center;
    background-repeat: no-repeat;
}
.adv-search-radiobtn {
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
}

.search-btn {
    padding: 10px 40px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
    background-color: #1CA9E1;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.search-mode-btn {
    color: #1CA9E1;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 20px;
    cursor: pointer;
}

/***** CATEGORY LINKS *****/

.categories-link {
    margin: 0 auto;
    padding-inline-start: 0;
    padding-top: 22px;
    top: -65px;
    left: 50%;
    max-width: 100%;
    height: 131px;
    position: absolute;
    z-index: 502;
    list-style: none;
    background-color: #FFF;
    -webkit-box-shadow: 0px 3px 6px -3px rgba(0,0,0,1);
    -moz-box-shadow: 0px 3px 6px -3px rgba(0,0,0,1);
    box-shadow: 0px 3px 6px -3px rgba(0,0,0,1);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    transform: translateX(-50%);
}
.categories-link li {
    padding-left: 45px;
    padding-right: 45px;
    display: inline-block;
    cursor: pointer;
}
.categories-link img {
    vertical-align: bottom;
}

/* Section - Common */

#offer-ads {
    margin-top: 75px;
}
.ads-section {
    background-color: #FFF;
}
.alternate-section {
    background-color: #F8F8F8;
}

/* Ads Section */

.ads-section-heading {
    color: #363636;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
}
.ad-card {
    background-color: transparent;
    border: none;
}
.ad-card-body {
    padding-left: 0;
    padding-right: 0;
}
.ad-img-wrapper {
    height: 174px;
}
.ad-img {
    border-radius: 5px;
    -webkit-box-shadow: 15px 15px 50px -25px rgba(0,0,0,1);
    -moz-box-shadow: 15px 15px 50px -25px rgba(0,0,0,1);
    box-shadow: 15px 15px 50px -25px rgba(0,0,0,1);
}
.ad-img-broken {
    background-color: #F2F2F2;
    background-image: url("images/logos/anshik-logo-greyscale.png");
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 5px;
    -webkit-box-shadow: 15px 15px 50px -25px rgba(0,0,0,1);
    -moz-box-shadow: 15px 15px 50px -25px rgba(0,0,0,1);
    box-shadow: 15px 15px 50px -25px rgba(0,0,0,1);
}
.ad-status {
    margin-top: -10px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 33px;
    width: 70px;
    height: 19px;
    position: relative;
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 19px;
    text-align: center;
    border: none;
    border-radius: 10px;
}
.ad-status-new {
    background-color: #37B837;
}
.ad-status-used {
    background-color: #1CA9E1;
}
.ad-status-running{
    background-color: #088E8B;
}
.ad-category {
    margin-bottom: 0.5rem;
    color: #A4A4A4;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
}
.ad-link:hover {
    text-decoration: none;
}
.ad-title {
    color: #242424;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    line-height: 25px;
}
.ad-location-wrapper {
    margin-bottom: 0;
}
.ad-location {
    color: #363636;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 18px;
}
.ad-by-wrapper {
    margin-bottom: 0;
}
.ad-by {
    color: #363636;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 18px;
}
.view-more-link {
    color: #A4A4A4;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
}
.view-more-link:hover {
    text-decoration: none;
}

/* How We Work Section */

#how-we-work {
    padding-bottom: 90px;
}
.hww-steps {
    width: 100%;
    display: table;
    position: relative;
}
.hww-steps-row {
    display: table-row;
}
.hww-step {
    display: table-cell;
    position: relative;
    text-align: center;
}
.hww-step:after {
    width: 50%;
    height: 1px;
    top: 40%;
    left: 75%;
    position: absolute;
    z-index: 0;
    content: " ";
    border: dashed 1px #CFCFCF;
}
.hww-step:last-child:after {
    border: none;
}
.hww-step .step-circle {
    width: 139px;
    height: 139px;
    display: inline-block;
    border: 2px solid #E1E1E1;
    border-radius: 50%;
}
.hww-step p {
    margin-top: 15px;
    color: #242424;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    line-height: 25px;
}

/* All Categories Section */

#all-categories {
    padding-bottom: 165px;
    background-image: url("images/bgs/footer-1-bg.png");
    background-repeat: repeat-x;
    background-position: center bottom;
}
.all-categories-masonry-grid {
    margin-left: -30px; /* gutter size offset */
    width: auto;
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
}
.all-categories-masonry-grid-column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
}
.category-card {
    padding: 1.5rem;
    border: none;
    border-radius: 5px;
}
.category-title-link,
.category-title-link:hover {
    text-decoration: none;
}
.category-title {
    color: #363636;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    line-height: 25px;
}
.category-title:hover {
    color: #A4A4A4;
}
.subcategory-list {
    padding-inline-start: 0;
    list-style: none;
}
.subcategory-item {
    color: #A4A4A4;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 30px;
    text-decoration: none;
}
.subcategory-item:hover {
    color: #363636;
    text-decoration: none;
}

/* Footer Section */

.footer-section {
    background-color: #EBEBEC;
}
.footer-card {
    background-color: #EBEBEC;
    border: none;
}
.footer-card-body {
    padding: 0;
}
.footer-logo {
    width: 156px;
}
.product-caption {
    color: #8B8B8B;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 22px;
}
.footer-category-title {
    color: #3A3A3A;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    line-height: 25px;
}
.footer-subcategory-list {
    padding-inline-start: 0;
    list-style: none;
}
.footer-subcategory-item, .footer-subcategory-item a {
    color: #8B8B8B;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 30px;
}
.footer-subcategory-item a:hover {
    color: #000;
    text-decoration: none;
}
.footer-section-copyright {
    color: #A4A4A4;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 30px;
}

/* Add Post Screen */

.add-post {
    border-top: 1px solid #BEEFEF;
}
.fw-content-row {
    padding-bottom: 200px;
    background-image: url("images/bgs/footer-2-bg.png");
    background-repeat: repeat-x;
    background-position: center bottom;
}
.add-post-heading-row {
    margin-top: 77px;
}
.add-post-heading {
    color: #363636;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    line-height: 35px;
}
.add-post-form {
    margin-top: 38px;
}
.add-post-form-label {
    color: #324444;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
}
.apf-select {
    height: 38px;
    color: #363636;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
    background-color: #FFF;
    border: 1px solid #CED4DA;
    border-radius: 5px;
}
.apf-select .dd-header-title {
    margin-left: 5px;
    margin-right: 5px;
}
.apf-select .fa-angle-up,
.apf-select .fa-angle-down {
    margin-right: 0;
}
.apf-textbox {
    height: 38px;
    color: #363636;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
    background-color: #FFF;
    border: 1px solid #CED4DA;
    border-radius: 5px;
}
.apf-textbox-step3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
}
.apf-textarea {
    color: #363636;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
    background-color: #FFF;
    border: 1px solid #CED4DA;
    border-radius: 5px;
}
.add-post-form-video-wrapper {
    height: 154px;
    background-image: url("images/icons/add-media.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #F2F2F2;
    border-radius: 5px;
}
.add-post-form-images-wrapper {
    padding: 15px;
    min-height: 155px;
    background-image: url("images/icons/add-media.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #F2F2F2;
    border-radius: 5px;
}
.add-post-form-gallery-label {
    color: #324444;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
}
.apf-delete-image {
    width: 22px;
    height: 22px;
    top: -10px;
    right: 5px;
    position: absolute;
    display: none;
    background-image: url("images/icons/delete-image.png");
    background-repeat: no-repeat;
    background-position: center center;
}
.ad-gallery-image {
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.25);
    border: none;
    border-radius: 5px;
}
.apf-bg-grey {
    padding: 20px;
    background-color: #F2F2F2;
    border-radius: 5px;
}
.apf-step3-row1 {
    padding: 22px;
    padding-left: 20px;
    padding-right: 20px;
}
.apf-step3-row1-label {
    color: #213333;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
}
.apf-step3-row1-value {
    color: #242424;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    line-height: 35px;
}
.apf-step3-row2 {
    margin-top: 30px;
}
.apf-input-group .input-group-text {
    background-color: #FFF;
}
.apf-input-group .apf-textbox {
    border-left: 0;
}
.apf-step3-balance-value {
    margin-bottom: 0;
    height: 38px;
    color: #213333;
    font-size: 18px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    line-height: 38px;
}
.apf-step3-balance-days {
    margin-left: 20px;
}
.apf-step1-button-row {
    margin-top: 47px;
}
.apf-step2-button-row {
    margin-top: 131px;
}
.apf-button {
    padding: 8px 20px;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    line-height: 21px;
    background-color: #1CA9E1;
    border: none;
    border-radius: 5px;
}
.apf-button:disabled {
    background-color: #C8C8C8;
}
.apf-button .fa-chevron-left {
    margin-right: 15px;
}
.apf-button .fa-chevron-right {
    margin-left: 15px;
}



/* Ajmal */
.pg-hbr4-pghlocselect-o-mobile {
    display:none;
}
.home-banner-title {
    text-align: right;
}
.normal-search-select {
    background-color: #FFF;
    -webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    -webkit-bottom-left-radius: 5px;
    -moz-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.normal-search-text {
    background-color: #FFF;
    -webkit-border-top-right-radius: 5px;
    -moz-border-top-right-radius: 5px;
    -webkit-bottom-right-radius: 5px;
    -moz-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

}

.advanced-search-box {
    text-align: right;
}

.home-banner-left-content {
    height: 100%;
}

.banner-container {
    height: 100% !important;
}
.mobile {
    display:none
}

@media only screen and (min-width: 1200px) {
.category-carousel {
    display: none;
}
}



@media only screen and (max-width: 991.98px){

    .pg-hbr4-home-banner-search-mode {
        height:585px !important;
    }
    .search-caption {
        text-align: center;
    }
    .ad-img-wrapper {
        height: 105px;
        /* height: auto; */
    }
    .ad-img {
        /* height: 120px !important;
        width: auto; */
    }

    .mobile {
        display:flex
    }
    .pg-hbr4-pghlocselect-o .pg-hbr4-pghselect__menu{
        min-width:200px !important;
        right:10px;
    }
    .header-drop-item {
        width: 50% !important;
    }

    .modal-content {
        width: 95% !important;
    }
    .no-show-mobile {
        display: none;
    }
    .navbar-logo {
        height: 25px !important;
    }
    .navbar-brand {
        margin-right: 0px !important;
    }
    .pg-hbr4-pghlocselect-o-mobile {
        display: inline !important;
        width:150px;
    }
    .add-post-btn-mobile {
        display: flex !important;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Inter', sans-serif;
        line-height: 21px;
        background-color: #1CA9E1;
        width:100%;
    }
    .nav-separator {
        display: none
    }
    .home-banner-title { text-align: center; }
    .normal-search-select {
        min-height: 49px;
        margin-bottom: 5px;
        -moz-border-radius: 5px;
        -webkit-radius: 5px;
        border-radius: 5px;

    }
    .normal-search-text {
        min-height: 49px;
        margin-bottom: 5px;
        -moz-border-radius: 5px;
        -webkit-radius: 5px;
        border-radius: 5px;

    }
    .input-group-text {
        -moz-border-radius: 5px;
        -webkit-radius: 5px;
        border-radius: 5px;
    }

    .home-banner-right-content .my-auto {
        margin:0 !important;
    }
    .home-banner-left-content .my-auto {
        margin:0 !important;
    }

    .search-btn{
        width:100%
    }
    .advanced-search-box-container .advanced-search-box {
        text-align: center;
        margin-top: 10px !important;
    }
    .home-banner-left-content {
        height: auto;
        margin-top:20px;
        margin-bottom:20px;
    }

    .banner-container {
        height: auto !important;
    }

    .home-banner-row-2 {
        display: none;
    }

    .footer-container{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fotter-container p,li,h4,img {
        text-align: center;
    }
    .footer-card-body{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .hww-step:after {
        width: 12% !important;
        height: 1px;
        top: 32%;
        left: 98%;
        position: absolute;
        z-index: 0;
        content: " ";
        border: dashed 1px #CFCFCF;
    }

    .hww-second-step:after {
        width: 0% !important;
        height: 1px;
        top: 60%;
        left: 75%;
        position: absolute;
        z-index: 0;
        content: " ";
        border: 0px !important;
    }

}

@media only screen and (max-width:1200px) {
    .categories-link{
        display: none;
    }
    .category-carousel {
        z-index: 999999;
        position: relative;
        width:100%;
        max-height: 100px;

        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        
        
    }
    .carousel-body{
        background-color: #fff;
        margin-top:-55px;
        width:80%;
        padding-top:30px;
        padding-bottom:30px;

        -webkit-box-shadow: 0px 3px 6px -3px rgba(0,0,0,1);
        -moz-box-shadow: 0px 3px 6px -3px rgba(0,0,0,1);
        box-shadow: 0px 3px 6px -3px rgba(0,0,0,1);
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
    }
    .prev-arrow{
        position: absolute;
        left:5%;
        background-color: #1CA9E1;
        display: flex;
        justify-content: center;
        align-items: center;
        height:40px;width:40px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    .next-arrow{
        position: absolute;
        right:5%;
        background-color: #1CA9E1;
        display: flex;
        justify-content: center;
        align-items: center;
        height:40px !important;width:40px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .carousel-arrow {
        color: #fff;
        font-size: 24px;
        text-align: center;
        font-weight: bold;
    }
    .custom-carousel-item {
        padding-left: 45px;
        padding-right: 45px;
        display: inline-block;
        cursor: pointer;
    }
    .custom-carousel-item img {
        vertical-align: bottom;
        max-height: 50px;
    }
    .sc-bxivhb {
        display:flex;
        align-items: center;
    }
}