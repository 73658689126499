.pg-22rt-myprops-wrapper {
    padding-top: 55px;
    padding-bottom: 220px;
    background-color: #FFF;
    background-image: url("../../../images/bgs/footer-2-bg.png");
    background-repeat: repeat-x;
    background-position: center bottom;
}

.pg-22rt-myp-heading-row {
    margin-bottom: 30px;
}
.pg-22rt-myp-heading {
    color: #363636;
    font-size: 20px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 30px;
}
.pg-22rt-myp-minimize {
    width: 20px;
    height: 100%;
    cursor: pointer;
    display: inline-block;
    background-image: url('../../../images/icons/pg-22rt-minimize.png');
    background-repeat: no-repeat;
    background-position: center;
}

.pg-22rt-ad-wrapper {
    margin-bottom: 35px;
    height: 174px;
    position: relative;
}
.pg-22rt-ad-link:hover {
    text-decoration: none;
}
.pg-22rt-wfa-overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 10;
    background-color: rgba(220, 219, 219, 0.89);
    border: none;
    border-radius: 5px;
}
.pg-22rt-wfa-text {
    top: 50%;
    left: 50%;
    position: absolute;
    color: #242424;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 27px;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
}

.pg-22rt-ad-img-wrapper {
    height: 174px;
}
.pg-22rt-ad-img {
    border-radius: 5px;
    box-shadow: 15px 15px 50px -25px rgba(0,0,0,1);
}
.pg-22rt-ad-details-col {
    border-right: 1px solid #CECECE;
}
.pg-22rt-ad-category {
    margin-bottom: 7px;
    color: #A4A4A4;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-22rt-ad-title {
    margin-bottom: 7px;
    color: #242424;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 25px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.pg-22rt-ad-lus-row {
    margin-bottom: 30px;
}
.pg-22rt-ad-location, .pg-22rt-ad-by {
    color: #363636;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 18px;
}
.pg-22rt-ad-status {
    padding: 1px 10px;
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 19px;
    text-align: center;
    border: none;
    border-radius: 10px;
}
.pg-22rt-ad-status-used {
    background-color: #1CA9E1;
}
.pg-22rt-ad-status-new {
    background-color: #37B837;
}
.pg-22rt-ad-total-price-label {
    color: #213333;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-22rt-ad-total-price-value {
    color: #242424;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 35px;
}

.pg-22rt-mshare-wrapper,
.pg-22rt-oshare-wrapper {
    padding: 20px;
    border-radius: 5px;
}
.pg-22rt-mshare-heading,
.pg-22rt-oshare-heading {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 19px;
}
.pg-22rt-mshare-row,
.pg-22rt-oshare-row {
    margin-top: 10px;
}

.pg-22rt-mshare-col {
    padding-right: 0
}
.pg-22rt-mshare-wrapper {
    background-color: #1CA9E1;
}
.pg-22rt-mshare-heading {
    color: #FFF;
}
.pg-22rt-mshare-label {
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 20px;
}
.pg-22rt-mshare-value {
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}

.pg-22rt-oshare-col1 {
    padding-left: 7.5px;
    padding-right: 7.5px;
}
.pg-22rt-oshare-col2 {
    padding-left: 0;
}
.pg-22rt-oshare-wrapper {
    background-color: #F2F2F2;
}
.pg-22rt-oshare-heading {
    color: #213333;
}
.pg-22rt-oshare-label {
    color: #ABA7A7;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 20px;
}
.pg-22rt-oshare-value {
    color: #242424;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-22rt-share-users {
    height: 100%;
    position: relative;
    background-image: url('../../../images/icons/pg-22rt-user-icon.png');
    background-repeat: no-repeat;
    background-position: center;
}
.pg-22rt-share-ucount {
    width: 19px;
    height: 19px;
    right: 0px;
    bottom: 0px;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins', Arial;
    line-height: 20px;
    text-align: center;
    position: absolute;
    background-color: #1CA9E1;
    border: none;
    border-radius: 50%;
}

/* KISSAN ADS - ICON, LABEL & VALUE */

.pg-22rt-prop-kissan-ilv-wrapper {
    padding-left: 10px;
}
.pg-22rt-prop-kissan-ilv-row {
    margin-bottom: 20px;
}
.pg-22rt-prop-kissan-icon-wrapper {
    width: 39px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}
.pg-22rt-prop-kissan-lv-wrapper {
    margin-left: 15px;
    display: inline-block;
    vertical-align: middle;
}
.pg-22rt-prop-kissan-ly-label {
    margin-bottom: 0;
    color: #A4A4A4;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.pg-22rt-prop-kissan-ly-value {
    margin-bottom: 0;
    color: #213333;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 25px;
}