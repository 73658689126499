.rangesilder-values {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins', Arial;
    line-height: 21px;
}
.rangeslider-current {
    color: #242424;
}
.rangeslider-balance {
    color: #8F8F8F;
}

.rangeslider {
    margin: 20px 0;
    position: relative;
    background-color: #DBEDED;
    border-radius: 39px;
    touch-action: none;
}
.rangeslider,
.rangeslider .rangeslider__fill {
    display: block;
}
.rangeslider .rangeslider__handle {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    background-color: #FFF;
    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.4), 10px 10px 50px rgba(0, 0, 0, 0.4);
    outline: none;
}
.rangeslider .rangeslider__handle .rangeslider__active {
    opacity: 1;
}
.rangeslider .rangeslider__handle-tooltip {
    width: 40px;
    height: 40px;
    left: 50%;
    display: inline-block;
    position: absolute;
    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 4px;
    transition: all 100ms ease-in;
    transform: translate3d(-50%, 0, 0);
}
.rangeslider .rangeslider__handle-tooltip span {
    margin-top: 12px;
    display: inline-block;
    line-height: 100%;
}
.rangeslider .rangeslider__handle-tooltip:after {
    width: 0;
    height: 0;
    position: absolute;
    content: ' ';
}

/**
* Rangeslider - Horizontal slider
*/
.rangeslider-horizontal {
    height: 25px;
}
.rangeslider-horizontal .rangeslider__fill {
    height: 100%;
    background-color: #53CF1C;
    border-radius: 39px;
}
.rangeslider-horizontal .rangeslider__handle {
    width: 39px;
    height: 39px;
    top: 50%;
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0);
}
.rangeslider-horizontal .rangeslider__handle-tooltip {
    top: -55px;
}
.rangeslider-horizontal .rangeslider__handle-tooltip:after {
    left: 50%;
    bottom: -8px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid rgba(0, 0, 0, 0.8);
    transform: translate3d(-50%, 0, 0);
}

/**
* Rangeslider - Labels
*/
.rangeslider__labels {
    position: relative;
}
.rangeslider__labels .rangeslider__label-item {
    top: 10px;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Arial;
    line-height: 21px;
    transform: translate3d(-50%, 0, 0);
}